import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProductList from './components/ProductList'; // Adjust the path if necessary
import ProductDetail from './components/ProductDetail'; // We'll create this component

function App() {
    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <h1>Welcome to the Product Store</h1>
                </header>
                <main>
                    <Routes>
                        <Route path="/" element={<ProductList />} />
                        <Route path="/product/:id" element={<ProductDetail />} />
                    </Routes>
                </main>
            </div>
        </Router>
    );
}

export default App;
